<template>

	<div>
		
		<div class="domains__col--half" v-if="domain && domain.Alexa">

			<h2>Alexa API</h2>

			<dl class="domain-ratings__definition domains__list">
				<dt>Online since</dt>
				<dd>{{ domain.Alexa.onlineSince ? domain.Alexa.onlineSince : 'N/A' }}</dd>
			</dl>
			<dl class="domain-ratings__definition domains__list">
				<dt>Connecting sites</dt>
				<dd>{{ domain.Alexa.connections ? domain.Alexa.connections : 'N/A' }}</dd>
			</dl>

			<h2>APIVoid API</h2>

			<dl class="domain-ratings__definition domains__list" v-if="domain.APIVoid && domain.APIVoid.details">
				<dt>Risk score</dt>
				<dd>{{ domain.APIVoid.details.detectionRate }}</dd>
			</dl>

			<dl class="domain-ratings__definition domains__list" v-if="domain.APIVoid && domain.APIVoid.flags">
				<dt>Flags</dt>
				<dd>
					<ul class="domain-ratings__engines">
						<li v-for="(flag, key) in domain.APIVoid.flags">
							<span class="material-icons">{{ flag ? 'check' : 'close' }}</span> {{ key }}
						</li>
					</ul>
				</dd>
			</dl>

			<dl class="domain-ratings__definition domains__list" v-if="domain.APIVoid">
				<dt>Reports in databases</dt>
				<dd>
					{{ domain.APIVoid.details.detections }} / {{ domain.APIVoid.details.enginesCount }}
					<template v-if="Object.values(domain.APIVoid.engines).filter(i => i.detected).length > 0">
						<span v-for="engine in Object.values(domain.APIVoid.engines).filter(i => i.detected)">
							<span class="material-icons">bug</span> {{ engine.engine }}, &nbsp;
						</span>
					</template>
					<div v-if="!showEngines">
						<a href="#" @click.prevent="showEngines = true" class="domain-ratings__showEngines">Show details</a>
					</div>
					<div v-if="showEngines">
						<ul class="domain-ratings__engines">
							<li v-for="engine in Object.values(domain.APIVoid.engines)">
								<span class="material-icons">{{ engine.detected ? 'bug' : 'check' }}</span> {{ engine.engine }}
							</li>
						</ul>
					</div>
				</dd>
			</dl>
			
		</div>

		<div class="domains__col--half">

			<h2>Data leak</h2>

			<template v-if="Object.values(domain.HaveIBeenPwned).length > 0">

				<template v-for="exploit in domain.HaveIBeenPwned">
					<dl class="domain-ratings__definition domains__list">
						<dt>Date of breach</dt>
						<dd>{{ parseDate(exploit.breachDate) }}</dd>
					</dl>
					<dl class="domain-ratings__definition domains__list">
						<dt>Exposed accounts</dt>
						<dd>{{ exploit.count }}</dd>
					</dl>
					<p class="domains__description" v-html="exploit.description"></p>
				</template>
				
			</template>
			<dl class="domain-ratings__definition domains__list" v-else>
				<dd>No recorded events</dd>
			</dl>


		</div>

	</div>
		
</template>
<script>
import moment from 'moment'
export default {
	data() {
		return {
			showEngines: false
		}
	},
	props: {
		domain: {
			type: Object,
			default: {}
		}
	},
	methods: {
		parseDate(date) {
  			return moment(date).format('DD.MM.YYYY. HH:MM')
  		}
	}
}
</script>