import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
	apiKey: "AIzaSyAoE8HlYMGo5x2Do6Q2EffZaVL8r_R7QFw",
	authDomain: "phishar-93495.firebaseapp.com",
	databaseURL: "https://phishar-93495.firebaseio.com",
	projectId: "phishar-93495",
	storageBucket: "phishar-93495.appspot.com",
	messagingSenderId: "79914862895",
	appId: "1:79914862895:web:649b68f713754bcd89f9da",
	measurementId: "G-VC3PVL821V"
}

export const db = firebase.initializeApp(firebaseConfig).firestore()

export const logActivity = (icon, message) => {

	// write it down
	db.collection('activities').add({
		message,
		icon,
		time: Date.now()
	})

}

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
// db.settings({ timestampsInSnapshots: true })