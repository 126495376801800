<template>

	<div v-if="domain && domain.ssl">
		
		<div class="domains__col--half">

			<dl class="domain-encryption__definition domains__list">
				<dt>Valid SSL</dt>
				<dd>{{ domain.ssl.valid ? 'Yes' : 'No' }}</dd>
			</dl>
			<dl class="domain-encryption__definition domains__list">
				<dt>Days remaining</dt>
				<dd>{{ domain.ssl.daysRemaining }}</dd>
			</dl>
			<dl class="domain-encryption__definition domains__list">
				<dt>Date of expiry</dt>
				<dd>{{ parseDate(domain.ssl.validTo) }}</dd>
			</dl>
			<dl class="domain-encryption__definition domains__list">
				<dt>Date of renewal</dt>
				<dd>{{ parseDate(domain.ssl.validFrom) }}</dd>
			</dl>

			<h2>SSL details</h2>

			<dl class="domain-encryption__definition domains__list">
				<dt>Issuer</dt>
				<dd>{{ domain.ssl.issuer.O }}, {{ domain.ssl.issuer.C }}</dd>
			</dl>

			<dl class="domain-encryption__definition domains__list">
				<dt>Fingerprint</dt>
				<dd><code>{{ domain.ssl.fingerprint }}</code></dd>
			</dl>


		</div>

		<div class="domains__col--half">

			<h2>Valid domains</h2>
			<ul class="domain-encryption__domains">
				<li v-for="site in domain.ssl.validFor">
					{{ site }}
				</li>
			</ul>


			<!-- <pre class="domains__raw-data">{{ domain.ssl }}</pre> -->

		</div>

	</div>
		
</template>
<script>
import moment from 'moment'
export default {
	props: {
		domain: {
			type: Object,
			default: {}
		}
	},
	methods: {
		parseDate(date) {
  			return moment(date).format('DD.MM.YYYY. HH:MM')
  		}
	}
}
</script>