<template>

	<div class="sidebar" v-if="$root.$data.user">

		<div class="sidebar__logo">
			<img src="https://phishar.com/assets/images/PhishAR-logo.svg">
		</div>

		<div class="sidebar__account">
			<img :src="$root.$data.user.photo" v-if="$root.$data.user.photo" class="sidebar__account-photo">
			<div class="sidebar__account-data">
				<div>{{ $root.$data.user.name }}</div>
				<a href="#" @click.prevent="logOut">
					<span class="material-icons">logout</span>
					Logout
				</a>
			</div>
		</div>

		<ul class="sidebar__navigation">
			<li :class="{ 'active': $route.name === 'Dashboard' }">
				<router-link :to="{ name: 'Dashboard' }">
					<span class="material-icons">insights</span>
					Dashboard
				</router-link>
			</li>
			<li :class="{ 'active': ~['Search'].indexOf($route.name) }">
				<router-link :to="{ name: 'Search' }">
					<span class="material-icons">travel_explore</span>
					Domain registry
				</router-link>
			</li>
			<li v-if="$route.name === 'Domain'" class="active">
				<router-link :to="{ name: 'Dashboard' }">
					<span class="material-icons">insert_chart</span>
					Domain details
				</router-link>
			</li>
			<li :class="{ 'active': ~['Sms'].indexOf($route.name) }">
				<router-link :to="{ name: 'Sms' }">
					<span class="material-icons">message</span>
					SMS registry
				</router-link>
			</li>
		</ul>

		<div class="sidebar__footer">
			v1.0.0
		</div>

	</div>

</template>
<script>
import firebase from 'firebase'
export default {
	methods: {
      logOut() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            location.reload()
          })
          .catch((error) => {
            console.log('There was an error')
          });
      }
    }
}
</script>