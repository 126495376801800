<template>
	
	<div class="domain-overview" v-if="domain && domain.PhishAR">
		
		<dl class="domain-overview__definition domains__list">
			<dt>Number of requests</dt>
			<dd>{{ domain.PhishAR.timesRequested }}</dd>
		</dl>

		<dl class="domain-overview__definition domains__list">
			<dt>Last data scrape</dt>
			<dd>{{ parseDate(domain.PhishAR.lastScrape) }}</dd>
		</dl>
		<dl class="domain-overview__definition domains__list">
			<dt>First occurance</dt>
			<dd>{{ parseDate(domain.PhishAR.firstScrape) }}</dd>
		</dl>

	</div>

</template>
<script>
import moment from 'moment'
export default {
	props: {
		domain: {
			type: Object,
			default: {}
		}
	},
	methods: {
		parseDate(date) {
  			return moment(date).format('DD.MM.YYYY. HH:MM')
  		}
	}
}
</script>