<template>
  
  <div class="dashboard">

      <Headerbar icon="insights" title="Dashboard" />

      <ul class="tabs">
        <li :class="{ 'active': tab === 'map' }">
          <a href="#" @click.prevent="tab = 'map'">Map view</a>
        </li>
        <li :class="{ 'active': tab === 'stats' }">
          <a href="#" @click.prevent="tab = 'stats'">Statistics</a>
        </li>
      </ul>
		
			<Map v-if="tab === 'map'" :items="latest" class="dashboard__map" />
      
      <div class="dashboard__stats">
        <Overview v-if="tab === 'stats'" class="dashboard__stats-overview" />
        <Latest v-if="tab === 'stats'"  :items="latest" class="dashboard__stats-latest" />
      </div>

	</div>

</template>

<script>
import { db } from '../db'
import Headerbar from '@/components/Headerbar'
import Overview from '@/components/Overview'
import Latest from '@/components/Latest'
import Map from '@/components/MapOverview'

const firestoreLimitItems = 5

export default {
  name: 'Dashboard',
  components: {
    Headerbar,
  	Overview,
  	Latest,
  	Map
  },
  data() {
  	return {
      tab: 'map',
  		requests: []
  	}
  },
  computed: {
    latest() { return this.requests.reverse() }
  },
  firestore: {
    requests: db.collection('requests').orderBy('time', 'asc').limitToLast(firestoreLimitItems)
  }
}
</script>