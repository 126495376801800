import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
import VueProgressBar from 'vue-progressbar'
import { firestorePlugin } from 'vuefire'

import { logActivity } from './db'

Vue.config.productionTip = false
Vue.use(firestorePlugin)

const progressBarOptions = {
  color: '#25dc8d',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, progressBarOptions)

new Vue({
  data() {
  	return {
  		user: false
  	}
  },
  router,
  store,
  render: h => h(App),
  beforeRouteEnter (to, from, next) {
    console.log('Entering route')
    next()
  },
  beforeRouteLeave (to, from) {
    console.log('Leaving route')
  },
  created() {

    this.$router.beforeEach((to, from, next) => {

  		if (to.meta.progress !== undefined) {
  			let meta = to.meta.progress
  			this.$Progress.parseMeta(meta)
  		}
      this.$Progress.start()
  		next()
      })
    
    this.$router.afterEach((to, from) => {
		  this.$Progress.finish()
    })

  	firebase
  	.auth()
  	.getRedirectResult()
  	.then((result) => {

		let user = result && result.user

		if (user === null) {

			if (router.history.current.name !== 'Login') {
				router.push({ name: 'Login' })
			}

		} 
		else {
      
			this.user = {
				name: user.displayName,
				email: user.email,
        photo: user.photoURL
			}

			if (router.history.current.name === 'Login') {
        logActivity('login', `${this.user.name} accessed the dashboard`)
				router.push({ name: 'Dashboard' })
			}

		}

	})
	.catch((error) => {
		console.log('There was an error with Google Auth', error)
	})

  }
}).$mount('#app')