<template>

	<div>
		
		<div class="domains__col--full">

			<template v-if="domain.scripts && domain.scripts.length > 0">
				
				<h2>Injected scripts ({{ domain.scripts.length }})</h2>

				<div class="domain-scripts__item" v-for="item in domain.scripts">
					<div class="domain-scripts__item-host">{{ item.host }}</div>
					<div class="domain-scripts__item-path">{{ item.path }}</div>
				</div>

			</template>

		</div>

	</div>

</template>
<script>
export default {
	props: {
		domain: {
			type: Object,
			default: {}
		}
	}
}
</script>