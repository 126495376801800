<template>

	<div v-if="domain && domain.domain">

		<div class="domains__col--half">

			<dl class="domain-data__definition domains__list">
				<dt>Register date</dt>
				<dd> {{ parseDate(domain.domain.dates.created) }}</dd>
			</dl>
			<dl class="domain-data__definition domains__list">
				<dt>Domain age</dt>
				<dd> {{ calculateAge(domain.domain.dates.created) }}</dd>
			</dl>

			<h2>Registrant</h2>
			<dl class="domain-data__definition domains__list">
				<dt>Details</dt>
				<dd v-html="registrantDetails"></dd>
			</dl>

			<h2>Registrar</h2>
			<dl class="domain-data__definition domains__list">
				<dt>Registrar URL</dt>
				<dd>{{ domain.domain.registrar.whois }}</dd>
			</dl>

		</div>
		<div class="domains__col--half">

			<h2>Nameservers</h2>
			<ul class="domain-data__nameservers">
				<li v-for="nameserver in domain.domain.nameservers">
					{{ nameserver }}
				</li>
			</ul>	

		</div>

	</div>

</template>
<script>
import moment from 'moment'
export default {
	props: {
		domain: {
			type: Object,
			default: {}
		}
	},
	computed: {
		registrantDetails() {
			return Object.values(this.domain.domain.registrant).join('<br>')
		}
	},
	methods: {
		parseDate(date) {
			let int = new Date(parseInt(date))
  			return moment(int).format('DD.MM.YYYY. HH:MM')
  		},
  		calculateAge(date) {
  			let register = new Date(parseInt(date))
  			return moment(register).fromNow().replace('ago', '')
  		}
	}
}
</script>