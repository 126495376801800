import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Domain from '@/views/Domain.vue'
import Dashboard from '@/views/Dashboard.vue'
import Search from '@/views/Search.vue'
import Home from '@/views/Home.vue'
import Sms from '@/views/Sms.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/address/:domain',
    name: 'Domain',
    component: Domain
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/sms',
    name: 'Sms',
    component: Sms
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/test',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
