<template>
	
	<div>

		<MglMap
			id="mapboxMap"
			:accessToken="accessToken" 
			:mapStyle.sync="mapStyle" 
			:zoom="1"
    		@load="onMapLoaded"
    		:center="location"
    		style="height: 200px; width: 100%;"
			>

			<MglMarker :coordinates="location" />

		</MglMap>		
		
	</div>

</template>
<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker, MglGeojsonLayer } from 'vue-mapbox'

export default {
	name: 'MapSingle',
	props: ['item', 'coordinates'],
	components: {
    	MglMap,
    	MglMarker
  	},
	data() {
		return {
      		accessToken: 'pk.eyJ1IjoibWFyaW9waGlzaGFyIiwiYSI6ImNrbDQ1YjA0NjFsejQyb2xiN3FjOGEybmoifQ.bshfHaVbj5vX72EUbFoI1Q',
      		mapStyle: 'mapbox://styles/mariophishar/ckn66iecx01at17nmz1v54ls9',
			map: null,
			mapOptions: {
				zoom: 1,
				maxZoom: 1,
				minZoom: 1
			}
		}
	},
	computed: {
		location() {
			return this.coordinates.reverse()
		}
	},
	created() {
    	this.mapbox = Mapbox
  	},
  	methods: {
	    onMapLoaded(event) {
	      // this.map = event.map
	    }
  	}
}
</script>