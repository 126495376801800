<template>
  <div class="overview">

    <div class="overview__item">
      <strong>Total domains</strong>
      <p>{{ stats.domains }}</p>
    </div>

    <div class="overview__item">
      <strong>Total requests</strong>
      <p>{{ stats.requests }}</p>
    </div>

    <div class="overview__item">
      <strong>Last request</strong>
      <p>{{ readableTime(stats.lastRequest) }}</p>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { db } from '../db'
export default {
  name: 'Overview',
  methods: {
    readableTime(time) {
      return moment(time).fromNow()
    }
  },
  data() {
    return {
      stats: {}
    }
  },
  firestore: {
    stats: db.collection('dashboard').doc('stats')
  }
}
</script>