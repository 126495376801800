<template>
  
	<div class="sms">

		<Headerbar title="SMS registry" icon="message">
      <template v-slot:action></template>
    </Headerbar>

    <div class="sms__single" v-for="message in reversedMessages">
      <div class="sms__singleText">
        {{ message.text }}
        <small>{{ message['message-timestamp'] }}</small>
      </div>
      <div class="sms__singleDetails">
        <dl>
          <dt>Decision</dt>
          <dd>
            <span class="material-icons" v-if="message.decision === 'valid'">check</span>
            <span class="material-icons" v-if="message.decision === 'spam'">dangerous</span>
            {{ message.decision.toUpperCase() }} message
          </dd>
        </dl>
        <dl>
          <dt>External link</dt>
          <dd><a :href="`https://analytics.phishar.com/smishing/check?track=${message.id}`" target="_blank">Open link</a></dd>
        </dl>
      </div>
    </div>
  
	</div>

</template>

<script>
import firebase from 'firebase'
import { db } from '../db'

import Headerbar from '@/components/Headerbar'

export default {
  name: 'Sms',
  components: {
  	Headerbar
  },
  data() {
    return {
      messages: []
    }
  },
  computed:{
    reversedMessages() {
      return this.messages.reverse()
    }
  },
  firestore () {
    return {
      messages: db.collection('smishing/messages/received').where('createdAt', '>', 0)
    }
  }
}
</script>