<template>

	<div>

		<div class="domains__col--half">

			<dl class="domain-data__definition domains__list">
				<dt>Server IP</dt>
				<dd>{{ domain.server.ip }}</dd>
			</dl>
			<dl class="domain-data__definition domains__list">
				<dt>Server location</dt>
				<dd>{{ domain.server.city }} {{ domain.server.country }} ({{ Object.values(domain.server.latLng).join(', ') }})</dd>
			</dl>
			<dl class="domain-data__definition domains__list">
				<dt>Time zone</dt>
				<dd>{{ domain.server.timeZone }}</dd>
			</dl>

		</div>

		<div class="domains__col--half">
			<Map v-if="domain && domain.server && domain.server.latLng" :coordinates="Object.values(domain.server.latLng)" />
		</div>

	</div>
		
</template>
<script>
import Map from '@/components/MapSingle'
export default {
	props: {
		domain: {
			type: Object,
			default: {}
		}
	},
	components: {
		Map
	}
}
</script>