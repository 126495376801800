<template>
  <div>
    
    <div class="headerbar">
        
      <div class="headerbar__icon">
        <span class="material-icons">{{ visualCue }}</span>
      </div>

      <form class="headerbar__input" @submit.prevent="runAction()">
        <input 
        type="text" 
        :placeholder="placeholder"
        ref="inputfield"

        v-model="value" 
        @focus="focused = true"
        @blur="focused = false"
      >
      </form>

      <div class="headerbar__actions" v-show="!focused">
        <slot name="action"></slot>
      </div>

    </div>

  </div>

</template>
<script>
import { logActivity } from '../db'
import urlParse from 'url-parse'
export default {
	data() {
		return {
			value: '',
			focused: false,
      parsed: {
        type: 'unknown',
        value: ''
      }
		}
	},
  methods: {
    runAction() {
      if (this.value.trim().length === 0) {
        return 
      }

      if (this.parsed.type === 'command') {
        console.log('Running command')
        alert('Ran command ' + this.parsed.value)
      }

      if (this.parsed.type === 'domain') {
        this.$router.push({ name: 'Domain', params: { 'domain': this.parsed.value }})
      }

    },
    parseInput() {
      this.parsed.type = this.isCommand ? 'command' : (this.isDomain ? 'domain' : 'unknown')

      // parse out the command
      if (this.isCommand) {
        this.parsed.value = this.value.substr(1, this.value.length)
      }

      // parse out the domain
      if (this.isDomain) {
        let { address } = this.parseDomain()
        this.parsed.value = address
      }

      // if nothing
      if (!this.isDomain && !this.isCommand) {
        this.parsed.value = this.value
      }

    },
    parseDomain() {

      let address = this.value.replace('https://', '').replace('http://')
      if (address.indexOf('/') > 0) {
        address = address.split('/')[0]
      }

      let segments = address.split('.').filter(x => x.length > 0)
      let dots = address.split('.').length - 1

      return { dots, segments, address }

    }
  },
  computed: {
    visualCue() {

      // if not focused
      if (!this.focused) {
        return this.icon
      }

      // if command
      if (this.isCommand) {
        return 'input'
      }

      // if domain
      if (this.isDomain) {
        return 'travel_explore'
      }

      // nothing, return default
      return 'edit'
        
    },
    isDomain() {
      let { dots, segments } = this.parseDomain()
      return dots > 0 && dots < 3 && segments.length > 1 && segments.length < 4
    },
    isCommand() {
      return this.value.length > 0 && this.value[0] === '/'
    }
  },
  watch: {
    value() {
      this.parseInput()
      this.$emit('change', this.parsed)
    }
  },
	props: {
		icon: {
			type: String,
			default: 'check_box_outline_blank'
		},
		navigate: {
			type: Boolean,
			default: true
		},
    title: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'What is this, no text?'
    }
	},
  mounted() {
    this.value = this.title
  }
}
</script>