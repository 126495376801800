<template>
  
  <div>

    <h2 class="domains__data-title">Latest requests</h2>

    <div class="latest">

      <div v-for="(request, index) in items.slice(0, 6)" class="latest__item" :key="request.id">
        <router-link 
          :to="{ name: 'Domain', params: { domain: request.domain }}" class="latest__item-domain">
          {{ request.domain }}
        </router-link>
        <div class="latest__item-location">from <span>{{ request.city }}</span>, {{ request.country }}</div>
        <div class="latest__item-time" :title="fullTime(request.time)">
          <vue-moments-ago prefix="" suffix="ago" :date="formatISO(request.time)" />
        </div>
        <span class="material-icons">chevron_right</span>
      </div>
      
    </div>

  </div>

</template>

<script>
import moment from 'moment'
import VueMomentsAgo from 'vue-moments-ago'
export default {
  name: 'Latest',
  components: {
    VueMomentsAgo
  },
  props: ['items'],
  methods: {
    fullTime(time) {
      return moment(time).format('MMM Do, YYYY - HH:mm')
    },
    formatISO(time) {
      return moment(time).format()
    }
  }
}
</script>