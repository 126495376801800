<template>
	
	<div>

		<MglMap 
			id="mapboxMap" 
			:accessToken="accessToken" 
			:mapStyle.sync="mapStyle" 
			:options="mapOptions"
			:zoom="-3"
			:interactive="true"
    		@load="onMapLoaded"
			>
			<!-- <MglMarker v-for="(request, index) in items" :key="request.id" :coordinates="Object.values(request.geo).reverse()">
				<span slot="marker"><i class="gps_ring"></i></span>
			</MglMarker> -->

			<MglMarker v-for="(location, index) in locations" :key="`rand_loc_${index}`" :coordinates="[location.lng, location.lat]">
				<span slot="marker"><i class="gps_ring"></i></span>
			</MglMarker>
		</MglMap>		
		
	</div>

</template>
<script>
import cities from 'cities.json'
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker, MglGeojsonLayer } from 'vue-mapbox'

export default {
	name: 'MapOverview',
	props: ['items'],
	components: {
    	MglMap,
    	MglMarker,
    	MglGeojsonLayer
  	},
	data() {
		return {
      		accessToken: 'pk.eyJ1IjoibWFyaW9waGlzaGFyIiwiYSI6ImNrbDQ1YjA0NjFsejQyb2xiN3FjOGEybmoifQ.bshfHaVbj5vX72EUbFoI1Q',
      		mapStyle: 'mapbox://styles/mariophishar/ckn66iecx01at17nmz1v54ls9',
			map: null,
			mapOptions: {
				zoom: -3,
				maxZoom: -3,
				minZoom: -3
			}
		}
	},
	computed: {
		locations() {
			return cities.filter(i => ['UK', 'US', 'PL', 'HR', 'SI', 'ES', 'BR', 'IT', 'FR', 'BE', 'IR'].indexOf(i.country) > -1).sort(() => .5 - Math.random()).slice(0,3)
		}
	},
	created() {
    	this.mapbox = Mapbox
  	},
  	methods: {
	    onMapLoaded(event) {
	      
	    }
  	}
}
</script>