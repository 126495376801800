<template>
  
	<div class="login">
		

		<div class="login__frame">
			<img src="https://phishar.com/assets/images/PhishAR-logo.svg">
			<button class="button" @click.prevent="loginWithGoogle">
				<span class="material-icons">login</span>
				Login with Google
			</button>
		</div>

	</div>

</template>

<script>
import firebase from 'firebase'
export default {
  name: 'Login',
  data: () => ({}),
  methods: {
  	loginWithGoogle() {

  		let provider = new firebase.auth.GoogleAuthProvider()
  		provider.setCustomParameters({
		  'hd': 'phishar.com'
		})

		firebase
			.auth()
			.signInWithRedirect(provider, )
  	}
  }
}
</script>