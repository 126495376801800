<template>
	<div>

    	<h2>Injected scripts ({{ items.length }})</h2>
    	<a class="button button--small" v-if="!showScripts" @click.prevent="showScripts = true">Show injected scripts</a>
	    <div v-else class="scripts">

	    	<div class="scripts__item" v-for="(item, index) in items">
	    		<div class="scripts__item-host">
	    			{{ item.host }}
	    		</div>
		    	<div class="scripts__item-path">
		    		{{ item.path }}
		    	</div>
	    	</div>
	      
	    </div>

  	</div>
</template>
<script>
export default {
	props: ['items'],
	data() {
		return {
			showScripts: true
		}
	}
}
</script>