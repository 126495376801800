<template>
  
	<div class="domains">

		<Headerbar :title="domain" icon="travel_explore">
      <template v-slot:action>
        <button class="button" slot="action" @click.prevent="updateSystem">
          <span class="material-icons">update</span>
          Re-scrape
        </button>
      </template>
    </Headerbar>

    <template v-if="address && address.system && address.system.apiVersion === 2">

      <ul class="tabs">
        <li :class="{ 'active': tab === 'overview' }">
          <a href="#" @click.prevent="changeTab('overview')">Overview</a>
        </li>
        <li :class="{ 'active': tab === 'domain' }">
          <a href="#" @click.prevent="changeTab('domain')">Domain</a>
        </li>
        <li :class="{ 'active': tab === 'server' }" >
          <a href="#" @click.prevent="changeTab('server')">Server</a>
        </li>
        <li :class="{ 'active': tab === 'encryption' }">
          <a href="#" @click.prevent="changeTab('encryption')">Encryption</a>
        </li>
        <li :class="{ 'active': tab === 'ratings' }">
          <a href="#" @click.prevent="changeTab('ratings')">Ratings</a>
        </li>
        <li :class="{ 'active': tab === 'scripts' }">
          <a href="#" @click.prevent="changeTab('scripts')">Scripts</a>
        </li>
      </ul>

      <div class="domains__tab" v-show="tab === 'overview'">

        <div class="domains__col--half">

          <img v-if="screenshot" :src="screenshot" alt="screenshot" style="width: 100%; height: auto;" class="domains__screenshot">

          <domain-overview :domain="address" />

        </div>
        <div class="domains__col--half">
          <Latest :items="latest" />
        </div>

      </div>
    
      <domain-data class="domains__tab" v-show="tab === 'domain'" :domain="address" />
      <domain-server class="domains__tab" v-show="tab === 'server'" :domain="address" />
      <domain-encryption class="domains__tab" v-show="tab === 'encryption'" :domain="address" />
      <domain-ratings class="domains__tab" v-show="tab === 'ratings'" :domain="address" />
      <domain-scripts class="domains__tab" v-show="tab === 'scripts'" :domain="address" />

    </template>
    <template v-else>
        <p class="domains__message" v-if="!rescrape">Please re-scrape the domain to upgrade it to V2</p>
        <p class="domains__message" v-if="rescrape">Updating system...</p>
    </template>
    
	</div>

</template>

<script>
import firebase from 'firebase'
import moment from 'moment'
import tldExtract from 'tld-extract'
import { db } from '../db'

import Headerbar from '@/components/Headerbar'
import Map from '@/components/MapSingle'
import Latest from '@/components/Latest'
import Scripts from '@/components/Scripts'

import DomainOverview from '@/components/domain/DomainOverview'
import DomainData from '@/components/domain/DomainData'
import DomainServer from '@/components/domain/DomainServer'
import DomainEncryption from '@/components/domain/DomainEncryption'
import DomainRatings from '@/components/domain/DomainRatings'
import DomainScripts from '@/components/domain/DomainScripts'

export default {
  name: 'Domain',
  components: {
  	Headerbar,
  	Latest,
  	Map,
  	Scripts,
  	DomainOverview,
  	DomainData,
  	DomainServer,
  	DomainEncryption,
  	DomainRatings,
    DomainScripts
  },
  data: () => ({
  	tab: 'overview',
  	address: {},
  	latest: [],
    lookup: {},
  	nameservers: false,
  	screenshot: false,
    rescrape: false,
    content: false,
  	raw: {
  		server: false,
  		domain: false,
  		PhishAR: false,
  		Alexa: false
  	}
  }),
  computed: {
  	domain() {
  		return this.$route.params.domain || null
  	}
  },
  watch: {
    lookup: {
      immediate: true,
      async handler(data) {

        if (!data.domains || this.domain === null) {
          return false
        }

        if (data && data.domains && data.domains.indexOf(this.domain) === -1) {
          // console.log('Updating system for domain', this.domain)
          await this.updateSystem(true)
          this.content = true
        } 
        else {
          this.content = true
        }

      }
    },
  	content: {
  		handler(data) {

        // if (data === null) {
        //   this.$router.push({ name: 'Home' })
        //   return false
        // }

        // if (this.lookup && this.lookup.domains && this.lookup.domains.indexOf(data) > 0) {

          this.$bind('address', db.collection('domains').doc(this.domain))
          this.$bind('latest', db.collection('requests').where('domain', '==', this.domain).orderBy('time', 'desc'))
          this.getScreenshot()

        // }

  		}
  	},
    address: {
      immediate: true,
      handler(data) {
        // if (Object.values(data).length === 0) {
        //   console.log('Triggered update')
        //   this.updateSystem(true)          
        // }
      }
    }
  },
  firestore: {
    lookup: db.collection('domains').doc('_lookup')
  },
  mounted() {},
  methods: {
    async updateSystem(isNew) {

      this.rescrape = true
      this.$Progress.start()

      const url = isNew ? 'https://analytics.phishar.com/api/v2/domain' : 'https://analytics.phishar.com/api/v2/update'
      console.log(url)

      const reqObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer 070707'
        },
        body: new URLSearchParams('domain=' + this.domain)
      }

      await fetch(url, reqObject)
      .then(response => {
        this.$Progress.finish()
      })
      .catch(e => {
        console.error('Error while updating system, redirecting...')
        this.$router.push({ name: 'Dashboard' })
      })

    },
  	changeTab(tab) {
  		this.tab = tab
  	},
  	parseDate(date) {
  		return moment(date).format('DD.MM.YYYY. HH:MM')
  	},
  	async getScreenshot() {
  		this.screenshot = await firebase.storage().ref(`screenshots/${this.domain.replace('.', '_')}.png`).getDownloadURL().then(url => url)
  	}
  }
}
</script>