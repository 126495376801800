<template>
	
	<div class="ticker" :class="{ 'app__ticker--closed': !open }" v-if="$root.$data.user">

		<template v-if="open">

			<template v-for="activity in last">

				<router-link class="notification" v-if="activity.action" :to="{ name: 'Domain', params: { domain: activity.action }}">
					<div class="notification__icon"><span class="material-icons">{{ activity.icon }}</span></div>
					<div class="notification__message">{{ activity.message }}</div>
				</router-link>

				<div class="notification" v-else>
					<div class="notification__icon"><span class="material-icons">{{ activity.icon }}</span></div>
					<div class="notification__message">{{ activity.message }}</div>
				</div>

			</template>
			
		</template>
		<template v-else>
			&larr;
		</template>

	</div>

</template>
<script>
import { db } from '../db'
export default {
	data() {
		return {
			activities: []
		}
	},
	computed: {
		last() {
			return this.activities.reverse()
		}
	},
  	props: {
		open: {
			type: Boolean,
			default: true
		}
	},
	firestore: {
		activities: db.collection('activities').orderBy('time', 'asc').limitToLast(10)
	}
}
</script>