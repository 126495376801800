<template>

		<div class="search">

      <Headerbar ref="headerbar" class="search__bar" icon="travel_explore" title="" placeholder="Type to search.." @change="handleQuery" />

      <div class="search__similar" v-if="exact">
        
        <h2>Result</h2>

        <ul class="search__domains search__domains--columns">
          <li class="search__domains">
            <router-link :to="{ name: 'Domain', params: { domain: query.value }}">
              <span>{{ query.value }}</span>
              <span class="material-icons">chevron_right</span>
            </router-link>
          </li>
        </ul>

      </div>

      <div class="search__similar" v-if="similar && similar.length > 0">
        
        <h2>Similar domains</h2>
        <ul class="search__domains search__domains--columns">
          <li v-for="domain in similar" class="search__domains">
            <router-link :to="{ name: 'Domain', params: { domain: domain.target }}">

              <span>{{ domain.target }}</span>
              <span class="material-icons">chevron_right</span>

            </router-link>
          </li>
        </ul>

      </div>

      <div class="search__recent">

        <h2>Recent lookups</h2>
        <ul class="search__domains search__domains--columns">
          <li v-for="domain in recent" class="search__domains">
            <router-link :to="{ name: 'Domain', params: { domain: domain }}">

              <span>{{ domain }}</span>
              <span class="material-icons">chevron_right</span>

            </router-link>
          </li>
        </ul>

      </div>
 
    </div>

</template>

<script>
import moment from 'moment'
import stringSimilarity from 'string-similarity'
import { db } from '../db'

import Headerbar from '@/components/Headerbar'

export default {
  name: 'Search',
  components: {
  	Headerbar
  },
  data: () => ({
    domains: [],
    results: [],
    requests: [],
    query: ''
  }),
  computed: {
    similar() {
      if (this.query && this.query.value && this.domains && this.domains.domains) {
        let list = stringSimilarity.findBestMatch(this.query.value, this.domains.domains)
        return list.ratings.sort((a, b) => b.rating - a.rating).splice(1, 6)
      }
      return []
    },
    recent() {
      let recent = this.requests.reverse()
      let list = []
      for(let domain of recent) {
        if (!(~list.indexOf(domain.domain))) {
          list.push(domain.domain)
        }
      }
      return list.splice(0, 8)
    },
    exact() {
      if (this.query && this.query.value && this.domains.domains.indexOf(this.query.value) > 0) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.$refs.headerbar.$refs.inputfield.focus()
  },
  methods: {
    handleQuery(query) {
      this.query = query
    },
  	parseDate(date) {
  		return moment(date).format('DD.MM.YYYY.')
  	},
    handleResults(r) {
      this.results = r.slice(0, 10)
    }
  },
  firestore: {
    domains: db.collection('domains').doc('_lookup'),
    requests: db.collection('requests').orderBy('time', 'asc').limitToLast(25)
  }
}
</script>