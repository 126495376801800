<template>
  <div id="app" class="app">

    <vue-progress-bar />
    <!-- <transition name="fade" mode="out-in"> -->

      <Sidebar class="app__sidebar" />

      <div class="app__main">

        <router-view/>

      </div>

      <Ticker class="app__ticker" />

    <!-- </transition> -->

  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import Ticker from '@/components/Ticker'

import '@/assets/scss/main.scss'

export default {
  components: {
    Sidebar,
    Ticker
  }
}
</script>